import React, { ReactElement } from "react";
import { ReactComponent as EyeIcon } from '../assets/EyeIcon.svg'

export default function ForgotPasswordCard(): ReactElement {
    
    return(
      <div className="bg-white shadow-md rounded-2xl p-12 flex flex-col">

        <div className="pb-14">
          <h1 className="card-title">Need help with your account?</h1>
          <p className="mt-8">Enter the email address associated with your account and we will send you a link to reset your password.</p>
        </div>

        <div className="mb-8">
          <label className="opacity-50 text-sm">Email</label>
          <input className="text-input" id="email" type="text" placeholder=""/>
        </div>
        <div className="flex items-center justify-center mb-6">
          <button className="continue-button">
              Send Link
          </button>
        </div>
        <div>
          <p className="text-center"><a href="/forgot-email" className="font-semibold">Forgot your email?</a></p>
        </div>
      </div>
    )
}
import React from 'react';
import ForgotPasswordCard from './components/ForgotPasswordCard';
import LoginCard from './components/LoginCard';
import RegisterCard from './components/RegisterCard';

function App() {
  return (
    <div className='flex items-center justify-center h-screen gap-8 bg-neutral-secondary'>
      <div>
        <div className="max-w-[520px] flex-1 mx-auto mb-8">
          <LoginCard/>
        </div>
        <div className="max-w-[520px] flex-1 mx-auto">
          <ForgotPasswordCard/>
        </div>
      </div>
      <div className="max-w-[900px] flex-1">
        <RegisterCard/>
      </div>
    </div>
  );
}

export default App;

import React, { ReactElement } from "react";
import { ReactComponent as EyeIcon } from '../assets/EyeIcon.svg'

export default function LoginCard(): ReactElement {
    
    return(
      <div className="bg-white shadow-md rounded-2xl p-12 flex flex-col">

        <div className="pb-14">
          <h1 className="card-title">Sign In</h1>
        </div>

        <div className="mb-6">
          <label className="opacity-50 text-sm">Username or Email</label>
          <input className="text-input" id="username" type="text" placeholder="Username *"/>
        </div>
        <div className="mb-6">
          <div className="relative">
            <input className="text-input" id="password" type="password" placeholder="Password *"/>
            <EyeIcon className="w-5 h-5 absolute right-0 top-3 mr-3"/>
          </div>
        </div>
        <div className="flex justify-between items-center mb-6">
          <div className="flex gap-3 items-center">
            <input className="rounded-checkbox" id="remember_me" type="checkbox" placeholder="Password *"/>
            <label htmlFor="remember_me">Remember Me</label>
          </div>
          <a className="text-primary-blue" href="/forgot-password">Forgot Password?</a>
        </div>
        <div className="flex items-center justify-center mb-6">
          <button className="continue-button">
              Continue
          </button>
        </div>
        <div>
          <p className="text-center">Don't have an account? <a href="/sign-up" className="font-semibold">Sign Up</a></p>
        </div>
      </div>
    )
}
import { E164Number } from "libphonenumber-js/types";
import React, { ReactElement } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import '../assets/phoneInput.css'

export default function RegisterCard(): ReactElement {
    
    function phoneNumberInput (e: E164Number | undefined) {
      console.log(e)
    }

    return(
      <div className="bg-white shadow-md rounded-2xl p-12 flex flex-col gap-14">

        <div className="grid grid-cols-2">
          <div className="mr-7">
            <h1 className="text-2xl font-bold mb-4">Contact Details</h1>
            <p>Your mobile will be used for two factor authentication to keep your account safe.</p>
          </div>
          <div className="flex flex-col justify-between">
            <div className="mb-6">
              <label className="opacity-50 text-sm">Email address*</label>
              <input className="text-input" id="email" type="text" placeholder=""/>
            </div>
            <div>
              <label className="opacity-50 text-sm">Mobile Phone *</label>
              <PhoneInputWithCountrySelect international={true} defaultCountry="GB" onChange={phoneNumberInput}/>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-2">
          <div className="mr-7">
            <h1 className="text-2xl font-bold mb-4">Security</h1>
            <p>Your password should be at least 8 characters long, contain at least 1 capital letter, 1 small letter, 1 number and 1 symbol</p>
          </div>
          <div className="flex flex-col justify-between">
            <div className="mb-6">
              <input className="text-input" id="password" type="password" placeholder="Password *"/>
            </div>
            <div>
              <input className="text-input" id="confirm-password" type="password" placeholder="Confirm Password *"/>
              <label className="opacity-50 text-sm">Try to use a mix of letters, numbers & symbols</label>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div className="mr-7">
            <h1 className="text-2xl font-bold mb-4">Preferences</h1>
            <p>All communication will be sent in your preferred language.</p>
          </div>
          <div className="flex flex-col justify-between">
            <div className="mb-6">
              <label className="opacity-50 text-sm">Preferred Language</label>
              <select className="text-input" id="language">
                <option value="English">English</option>
                <option value="English">English</option>
              </select>
            </div>
            <div>
              <input className="text-input" id="referral" type="text" placeholder="Referral Code"/>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div className="mr-7">
            <h1 className="text-2xl font-bold mb-4">Identity Verification</h1>
            <p>Your date of birth and Social Security Number are required to verify your identity and to open an account. Your information is safe and secure.</p>
          </div>
          <div className="flex flex-col justify-between">
            <div className="mb-6">
              <input className="text-input" id="dob" type="text" placeholder="Date of Birth*"/>
            </div>
            <div>
              <input className="text-input" id="ssn" type="text" placeholder="SSN*"/>
              <label className="opacity-50 text-sm underline">Why do you need my Social Security Number?</label>
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-col items-center text-sm mb-6">
            <div>
              <div className="flex gap-3 items-center pb-2">
                <input className="rounded-checkbox" id="membership_agreement" type="checkbox"/>
                <label htmlFor="membership_agreement">I have read and agreed to the <a className="text-primary-blue">membership agreement</a></label>
              </div>
              <div className="flex gap-3 items-center">
                <input className="rounded-checkbox" id="terms_and_conditions" type="checkbox"/>
                <label htmlFor="terms_and_conditions">I have read and agreed to the <a className="text-primary-blue">terms and conditions</a></label>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <button className="continue-button">
                Continue
            </button>
          </div>
        </div>
      </div>
    )
}